import React, { useEffect, useRef, useState } from "react";
import DailyRegisterPaymentTableRow from "./DailyRegisterPaymentTableRow";

import "./Table.css";
import "./DailyRegisterTable.css";
import Loader from "./Loader";
import DailyRegisterOtherPaymentTableRow from "./DailyRegisterOtherPaymentTableRow";
import VerifyPinContainer from "./VerifyPinContainer";

const DailyRegisterTable = (props) => {
  let paymentTableCols = [
    "Sl",
    "ID",
    "Name",
    "Course",
    "Amount",
    "For Month",
    "Mode",
    "Date",
    "Edit",
    "Delete",
    "Print",
    "Share",
  ];
  let otherPaymentTableCols = [
    "ID",
    "Name",
    "For",
    "Amount",
    "Date",
    "Edit",
    "Delete",
    "Print",
    "Share",
  ];

  const [fromDate, setFromDate] = useState(new Date().toJSON().slice(0, 10));
  const [toDate, setToDate] = useState(new Date().toJSON().slice(0, 10));
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [paymetnTableClasses, setPaymentTableClasses] = useState(
    "table-container d-none"
  );
  const [otherPaymetnTableClasses, setOtherPaymentTableClasses] = useState(
    "table-container d-none"
  );
  const [
    dailyRegisterBoxesContainerClasses,
    setDailyRegisterBoxesContainerClasses,
  ] = useState("daily_register_box_data_container d-none");
  const [paymentRowsData, setPaymentRowsData] = useState([]);
  const [otherPaymentRowData, setOtherPaymentRowData] = useState([]);
  const [totalCash, setTotalCash] = useState(0);
  const [totalOnline, setTotalOnline] = useState(0);
  const [totalOtherPayment, setTotalOtherPayment] = useState(0);
  const [enteredPin, setEnteredPin] = useState("");
  const [verifyPinClasses, setVerifyPinClasses] = useState(
    "verify-pin-container d-none"
  );
  const [receiptNo, setReceiptNo] = useState("");
  const [typeOfDeletion, setTypeOfDeletion] = useState("");

  const [deviceType, setDeviceType] = useState(null);

  const cashPaymentContainer = useRef("");
  const onlinePaymentContainer = useRef("");
  const othersPaymentContainer = useRef("");

  useEffect(() => {
    // checkScreenSize
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression containing some mobile devices keywords to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in detail it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    isMobileDevice ? setDeviceType("mobile") : setDeviceType("desktop");
  }, []);

  // let monthNames = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

  const printAll = () => {
    cashPaymentContainer.current.classList.remove("overflowY-scroll");
    onlinePaymentContainer.current.classList.remove("overflowY-scroll");
    othersPaymentContainer.current.classList.remove("overflowY-scroll");
    window.print();
    cashPaymentContainer.current.classList.add("overflowY-scroll");
    onlinePaymentContainer.current.classList.add("overflowY-scroll");
    othersPaymentContainer.current.classList.add("overflowY-scroll");
  };

  const cancelPinHandler = () => {
    setVerifyPinClasses("verify-pin-container d-none");
    setEnteredPin("");
  };

  const proceedPinHandler = () => {
    if (enteredPin.length !== 0) {
      let paymentDeleteData = {
        receipt_no: receiptNo,
        entered_verify_pin: enteredPin,
      };

      setEnteredPin("");
      // setEnteredCatpcha('');
      setVerifyPinClasses("verify-pin-container d-none");
      if (typeOfDeletion === "P")
        //p-->Paid Fees
        deletePaidFeesHandler(paymentDeleteData);
      else if (typeOfDeletion === "O")
        //O --> Others Payment
        deleteOtherPaymentHandler(paymentDeleteData);
    } else {
      alert("Please Enter Pin");
    }
  };

  const fetchPaymentDetailsWithRange = () => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/get_daily_register_payment_data.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({ from_date: fromDate, to_date: toDate }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setPaymentTableClasses("table-container");
          setPaymentRowsData(data.data.payment_rows);
          setTotalCash(data.data.total_cash);
          setTotalOnline(data.data.total_online);
        } else {
          setPaymentTableClasses("table-container d-none");
          setPaymentRowsData([]);
          setTotalCash(0);
          setTotalOnline(0);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const deletePaidFeesHandler = (deleteData) => {
    setLoaderClasses("loader-container");
    console.log(JSON.stringify(deleteData));
    //deletion code will go here
    fetch(
      `${props.hostName}/redsky_api/deletePaidFees.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(deleteData),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 12) {
          setPaymentRowsData([]);
          fetchPaymentDetailsWithRange();
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const deleteOtherPaymentHandler = (deleteData) => {
    setLoaderClasses("loader-container");

    //deletion code will go here
    fetch(
      `${props.hostName}/redsky_api/deleteOtherPayment.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(deleteData),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 12) {
          setOtherPaymentRowData([]);
          fetchOtherPaymentDetailsWithRange();
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const fetchOtherPaymentDetailsWithRange = () => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/get_daily_register_other_payment_data.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({ from_date: fromDate, to_date: toDate }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        // console.log(data);
        if (data.code === 5) {
          setOtherPaymentTableClasses("table-container");
          setOtherPaymentRowData(data.data.other_payment_rows);
          setTotalOtherPayment(data.data.total_other_payment);
        } else {
          setOtherPaymentTableClasses("table-container d-none");
          setOtherPaymentRowData([]);
          setTotalOtherPayment(0);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const fetchDailyRegisterData = (e) => {
    e.preventDefault();
    fetchPaymentDetailsWithRange();
    fetchOtherPaymentDetailsWithRange();
    setDailyRegisterBoxesContainerClasses("daily_register_box_data_container");
  };

  return (
    <>
      <div className="daily-register">
        <header className="table-header">
          <span>Daily Register</span>
          <div className="pos-rel">
            <div className="d-flex align-center">
              {/* <span className="material-icons material-symbols-outlined" onClick={filterHandler}>tune</span> */}
              <span
                className="material-icons material-symbols-rounded"
                onClick={printAll}
              >
                print
              </span>
            </div>
          </div>
        </header>
        <p className="date-range-msg d-none">
          Date Range: {fromDate} to {toDate}
        </p>

        <form
          className="daily-register-range-form"
          onSubmit={fetchDailyRegisterData}
        >
          <div className="column">
            <div className="input-box">
              <label>
                From<span className="color-red">*</span>
              </label>
              <input
                type="date"
                placeholder="Enter from date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                required
              />
            </div>
            <div className="input-box">
              <label>
                To<span className="color-red">*</span>
              </label>
              <input
                type="date"
                placeholder="Enter to date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                required
              />
            </div>
          </div>
          <input type="submit" className="submit-button" value="Submit" />
        </form>
        {/* patment box boxes start */}
        <div className={dailyRegisterBoxesContainerClasses}>
          <div className="title">
            <span className="material-icons material-symbols-outlined">
              speed
            </span>
            <span className="text">Payment Details</span>
          </div>

          <div className="boxes">
            <div className="box box1">
              <span className="material-icons material-symbols-outlined">
                savings
              </span>
              <span className="text">Total</span>
              <span className="number">
                &#8377;{totalCash + totalOnline + totalOtherPayment}
              </span>
              {/* <span className="sub-text">This Month</span> */}
            </div>
            <div className="box box2">
              <span className="material-icons material-symbols-outlined">
                account_balance
              </span>
              <span className="text">Cash</span>
              <span className="number">&#8377;{totalCash}</span>
              {/* <span className="sub-text">This Month</span> */}
            </div>
            <div className="box box3">
              <span className="material-icons material-symbols-outlined">
                account_balance_wallet
              </span>
              <span className="text">Online</span>
              <span className="number">&#8377;{totalOnline}</span>
              {/* <span className="sub-text">This Month</span> */}
            </div>
            <div className="box box4">
              <span className="material-icons material-symbols-outlined">
                payments
              </span>
              <span className="text">Others</span>
              <span className="number">&#8377;{totalOtherPayment}</span>
              {/* <span className="sub-text">This Month</span> */}
            </div>
          </div>
        </div>
        {/*  patment box boxes end */}
        {/* fees payment table */}
        <div className={paymetnTableClasses}>
          <div className="table-captions">
            <h2 className="table-caption">Cash Payments</h2>
            <div className="payments-total-text">
              <span className="d-flex cash">
                <span className="material-icons material-symbols-outlined">
                  currency_rupee
                </span>
                &nbsp;
                <p>Total: {totalCash}/-</p>
              </span>
            </div>
            <div
              className="table-container overflowY-scroll"
              ref={cashPaymentContainer}
            >
              <table>
                <thead>
                  <tr>
                    {paymentTableCols.map((element) => {
                      return (
                        <th scope="col" key={element} data={element}>
                          {element}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <DailyRegisterPaymentTableRow
                    paymentRowsData={paymentRowsData.filter(
                      (elem) => elem.payment_mode.toLowerCase() === "cash"
                    )}
                    deletePaidFeesHandler={deletePaidFeesHandler}
                    setReceiptNo={setReceiptNo}
                    setVerifyPinClasses={setVerifyPinClasses}
                    setTypeOfDeletion={setTypeOfDeletion}
                    deviceType={deviceType}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* online payment details start  */}
        <div className={paymetnTableClasses}>
          <div className="table-captions">
            <h2 className="table-caption">Online Payments</h2>
            <div className="payments-total-text">
              <span className="d-flex online">
                <span className="material-icons material-symbols-outlined">
                  currency_rupee
                </span>
                &nbsp;
                <p>Total: {totalOnline}/-</p>
              </span>
            </div>
            <div
              className="table-container overflowY-scroll"
              ref={onlinePaymentContainer}
            >
              <table>
                <thead>
                  <tr>
                    {paymentTableCols.map((element) => {
                      return (
                        <th scope="col" key={element} data={element}>
                          {element}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <DailyRegisterPaymentTableRow
                    paymentRowsData={paymentRowsData.filter(
                      (elem) => elem.payment_mode.toLowerCase() === "online"
                    )}
                    deletePaidFeesHandler={deletePaidFeesHandler}
                    setReceiptNo={setReceiptNo}
                    setVerifyPinClasses={setVerifyPinClasses}
                    setTypeOfDeletion={setTypeOfDeletion}
                    deviceType={deviceType}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* payment table end*/}
        {/* other fees payment table start*/}
        <div className={otherPaymetnTableClasses}>
          <div className="table-captions">
            <h2 className="table-caption">Other Payment Details</h2>
            <div className="payments-total-text">
              <span className="d-flex others">
                <span className="material-icons material-symbols-outlined">
                  currency_rupee
                </span>
                &nbsp;
                <p>Total: {totalOtherPayment}/-</p>
              </span>
            </div>
            <div
              className="table-container overflowY-scroll"
              ref={othersPaymentContainer}
            >
              <table>
                <thead>
                  <tr>
                    {otherPaymentTableCols.map((element) => {
                      return (
                        <th scope="col" key={element} data={element}>
                          {element}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <DailyRegisterOtherPaymentTableRow
                    otherPaymentRowData={otherPaymentRowData}
                    deleteOtherPaymentHandler={deleteOtherPaymentHandler}
                    setReceiptNo={setReceiptNo}
                    setVerifyPinClasses={setVerifyPinClasses}
                    setTypeOfDeletion={setTypeOfDeletion}
                    deviceType={deviceType}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* other fees payment table end*/}
        <VerifyPinContainer
          verifyPinClasses={verifyPinClasses}
          enteredPin={[enteredPin, setEnteredPin]}
          proceedPinHandler={proceedPinHandler}
          cancelPinHandler={cancelPinHandler}
        />
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default DailyRegisterTable;
