import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./Home.css";

const Home = (props) => {
  localStorage.removeItem("admin_data_redsky");
  const [isLoadVisible, setIsLoadVisible] = useState("");
  const menuBtn = useRef("");
  const navBar = useRef("");

  const menuBtnClickhandler = () => {
    menuBtn.current.classList.toggle("active");
    navBar.current.classList.toggle("d-block");
  };

  useEffect(() => {
    props.setTitle(
      "Admin | Administrator Portal | RedSky Educations | Education for future"
    );
    const onPageLoad = () => {
      setIsLoadVisible("d-none");
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, [props]);

  return (
    <div>
      {/* <!-- ***** Preloader Start ***** --> */}
      <div className={`preloader ${isLoadVisible}`}>
        <div className="preloader-inner">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {/* <!-- ***** Preloader End ***** --> */}

      {/* <!-- ***** Header Area Start ***** --> */}
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                {/* <!-- ***** Logo Start ***** --> */}
                <Link to="./" className="logo">
                  <h4>
                    Red<span>Sky</span>{" "}
                  </h4>
                </Link>
                {/* <!-- ***** Logo End ***** --> */}
                {/* <!-- ***** Menu Start ***** --> */}
                <ul className="nav" ref={navBar}>
                  <li className="scroll-to-section">
                    <Link to="/" className="active">
                      Home
                    </Link>
                  </li>
                  {/* <li className="scroll-to-section"><a href="#about">About Us</a ></li> */}
                  {/* <li className="scroll-to-section"><a href="#services">Services</a ></li> */}
                  {/* <li className="scroll-to-section"><a href="#portfolio">Portfolio</a ></li> */}
                  <li className="scroll-to-section">
                    <Link to="/task_panel/login">Task</Link>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/admin_panel/login">Login</Link>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/policies">Policy</Link>
                  </li>

                  <li className="scroll-to-section">
                    <div className="main-red-button">
                      <a href="#contact">Contact Now</a>
                    </div>
                  </li>
                </ul>
                <button
                  className="menu-trigger"
                  onClick={menuBtnClickhandler}
                  ref={menuBtn}
                >
                  <span>Menu</span>
                </button>
                {/* <!-- ***** Menu End ***** --> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center hero-text-container">
                  <div
                    className="left-content header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <h6>
                      Welcome to RedSky Educations <small>[Admin Portal]</small>
                    </h6>
                    <h2>
                      We Make <em>Careers</em> &amp; <span>Futures</span> of
                      Students
                    </h2>
                    <p>
                      We offer a wide range of educational programs and training
                      in Computer Science and Technology{" "}
                    </p>
                  </div>
                  <div>
                    <p className="student-portal-link">
                      <Link to="/student" className="d-oflex align-center">
                        Click for Student Portal &nbsp;
                        <span
                          className="material-icons material-symbols-outlined"
                          style={{ fontSize: "1.2rem" }}
                        >
                          open_in_new
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img
                      src="./external/assets/images/banner-right-image.png"
                      alt="team meeting"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="contact-us section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <div className="section-heading">
                <h2>Feel Free To Send Us a Message About Your Concern</h2>
                <p>
                  Please go ahead and share your concern or question. Our team
                  will try best to assist you
                </p>
                <div className="phone-info">
                  <h4 className="d-flex">
                    For any enquiry, Call Us:
                    <span className="d-flex">
                      <span className="material-icons material-symbols-outlined call-icons">
                        call
                      </span>
                      <Link to="tel:+91 9474897192">9474897192</Link>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <form id="contact" action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Name"
                        autoComplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        type="surname"
                        name="surname"
                        id="surname"
                        placeholder="Surname"
                        autoComplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        placeholder="Your Email"
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        type="text"
                        className="form-control"
                        id="message"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button
                        type="submit"
                        id="form-submit"
                        className="main-button "
                      >
                        Send Message
                      </button>
                    </fieldset>
                  </div>
                </div>
                <div className="contact-dec">
                  <img
                    src="./external/assets/images/contact-decoration.png"
                    alt=""
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.25s"
            >
              <p>
                © Copyright @ {new Date().getFullYear()} RedSky Educations Pvt.
                Ltd. All Rights Reserved.
                <br />
                Design & Developed By{" "}
                <Link
                  rel="nofollow noreferrer"
                  target="_blank"
                  to="https://www.linkedin.com/in/iamgourabtalukdar/"
                >
                  Gourab Talukdar
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* <h2>Home</h2>
      <Link to='./faq'>FAQ</Link>
      <br />
      <Link to='./student_panel'>Student</Link>
      <br />
      <Link to='./admin_panel'>Admin</Link> */}
    </div>
  );
};

export default Home;
