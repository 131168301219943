import React, { useEffect, useState } from "react";
// import examLogo from "../../assets/img/exam_logo.jpg";
// import pointIcon from "../../assets/img/point_icon.jpg";
import "./PanelChoice.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import Loader from "../../components/admin_components/Loader";

const PanelChoice = (props) => {
  props.setTitle("Panel Choice | Student Panel | RedSky Educations");
  const param = useParams();
  const navigate = useNavigate();
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [stuName, setStuName] = useState("");
  //   const [stuSubId, setStuSubId] = useState("");
  const [isAccountShow, setIsAccountShow] = useState(false);
  // const [alertShow, setAlertShow] = useState(false);

  //   const checkIsExamAvailable = () => {
  //     // setLoaderClasses('loader-container');
  //     fetch(
  //       `${props.hostName}/redsky_api/student/exam_is_available.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`
  //     )
  //       .then((resp) => resp.json())
  //       .then((data) => {
  //         if (data.code === 5) {
  //           if ("exam_status" in data.data) {
  //             if (
  //               !window.confirm(
  //                 "Your Browser Will be Opened in Full Screen Mode. Do You Want to Proceed?"
  //               )
  //             )
  //               return;
  //             navigate(
  //               `../exam/${data.data.sub_id}/${param.contact}/${data.data.first_question_id}`
  //             );
  //           } else {
  //             alert(data.data);
  //           }
  //         } else {
  //           alert(data.data);
  //         }
  //         // setLoaderClasses('loader-container d-none');
  //       })
  //       .catch((err) => console.log(err));
  //   };

  useEffect(() => {
    // if(!localStorage.getItem("redsky_stu_welcome_msg")){
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/student/student_welcome.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          //   setStuSubId(data.data.stu_welcome_data.sub_id);
          setStuName(data.data.stu_welcome_data.first_name);
          // setAlertShow(true);
        } else {
          alert(data.data);
          navigate("../");
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
    // }
  }, [param.contact, param.reg_no, props.hostName, navigate]);

  return (
    <div
      className="container-fluid text-center min-vh-100 bg-white pt-4"
      id="panel_choice"
    >
      <div className="position-relative d-flex align-items-center justify-content-end pe-3 cursor-pointer">
        <div
          onClick={() => setIsAccountShow(!isAccountShow)}
          className=" d-flex align-items-center justify-content-center"
        >
          <span className="material-icons material-symbols-outlined prevent-select account-icon me-1">
            account_circle
          </span>{" "}
          {stuName}
        </div>

        <div
          className={`position-absolute top-100 mt-1 bg-white border border-2 rounded p-2  ${
            isAccountShow ? "" : "d-none"
          }`}
        >
          <h6 style={{ marginBottom: "0.4rem" }}>{stuName}</h6>
          <Link to="../" className="d-flex text-decoration-none">
            Log Out &nbsp;{" "}
            <span
              className="material-icons material-symbols-outlined prevent-select"
              style={{ fontSize: "1.2rem" }}
            >
              logout
            </span>
          </Link>
        </div>
      </div>
      <h3 className="mb-5">Student Panel</h3>
      <div className="row d-flex justify-content-center align-items-center p-4 gap-4">
        <Link
          to={`../basic_details/${param.reg_no}/${param.contact}`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
        >
          {/* <img src={pointIcon} alt='point_icon' id='exam-logo' /> */}
          <span className="material-icons material-symbols-outlined">
            person
          </span>
          <h5>Basic Details</h5>
        </Link>

        {/* <div
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
          style={{ cursor: "pointer" }}
          onClick={checkIsExamAvailable}
        >
          <img src={examLogo} alt="exam_logo" id="exam-logo" />
          <h5>Exam Panel</h5>
        </div> */}

        {/* <Link
          to={`../point_dashboard/${stuSubId}/${param.contact}`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
        >
          <img src={pointIcon} alt="point_icon" id="exam-logo" />
          <h5>Point Panel</h5>
        </Link> */}

        <Link
          to={`../update-contact/${param.reg_no}/${param.contact}/true`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
        >
          <span className="material-icons material-symbols-outlined">
            contact_page
          </span>
          <h5>Update Contact</h5>
        </Link>

        <Link
          to={`../payment-details/${param.reg_no}/${param.contact}`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box box1"
        >
          <span className="material-icons material-symbols-outlined">
            receipt_long
          </span>
          <h5>Payment</h5>
        </Link>

        <Link
          to={`../log-book/${param.reg_no}/${param.contact}`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
        >
          <span className="material-icons material-symbols-outlined">task</span>
          <h5>Class Log Book</h5>
        </Link>

        <Link
          to={`../attendace-details/${param.reg_no}/${param.contact}`}
          className="col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box"
        >
          <span className="material-icons material-symbols-outlined">
            calendar_month
          </span>
          <h5>Attendance</h5>
        </Link>

        {/* <Link to={`../marks-data/${stuSubId}/${param.contact}`} className='col-lg-2 col-md-3 col-sm-4 col-12 bg-white px-2 pt-3 pb-4 rounded text-decoration-none text-dark stu_panel_link_box'>
                    <span className="material-icons material-symbols-outlined" >stacked_bar_chart</span>
                    <h5>Marks</h5>
                </Link>  */}
      </div>

      <Loader loaderClasses={loaderClasses} />
      {!localStorage.getItem("redsky_stu_welcome_msg") ? (
        <Alert
          alert="true"
          strongMsg="Welcome Back"
          msg={`, ${stuName}`}
          time={5000}
          pos="top"
          isWelcomeMsg={true}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PanelChoice;
