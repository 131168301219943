import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "./PaidFeesListStudent.css";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
import logo from "../../assets/img/redsky logo.jpeg";
// import PaidFeesTableRowStudent from '../../components/student_components/PaidFeesTableRowStudent';

const PaymentSuccess = (props) => {
  //   props.setTitle("RedSky Digital Passbook | Student Panel | RedSky Educations");
  const param = useParams();

  const [paymentRespArr, setPaymentRespArr] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");

  useEffect(() => {
    setLoaderClasses("loader-container");
    setPaymentRespArr(param.paymentResp.split("&"));
    setLoaderClasses("loader-container d-none");
  }, [param.paymentResp]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Payment Successful | Student Panel | RedSky Educations"
        />
        <title>Payment Successful | Student Panel | RedSky Educations</title>
      </Helmet>

      <div className="d-flex flex-col min-h-screen">
        <header className="d-flex justify-content-center px-4 py-2 border-b">
          <Link to="../">
            <img src={logo} alt="logo" height="120" />
          </Link>
        </header>
        <div className="border rounded shadow-sm mt-4 mx-3">
          <main className="d-flex flex-col align-items-center justify-content-center flex-grow-1 text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              style={{ fill: "#40C057" }}
            >
              <path d="M 12 2 C 6.4889941 2 2 6.4889982 2 12 C 2 17.511002 6.4889941 22 12 22 C 17.511006 22 22 17.511002 22 12 C 22 6.4889982 17.511006 2 12 2 z M 16 9 C 16.25575 9 16.511531 9.0974687 16.707031 9.2929688 C 17.098031 9.6839688 17.098031 10.316031 16.707031 10.707031 L 11.707031 15.707031 C 11.512031 15.902031 11.256 16 11 16 C 10.744 16 10.487969 15.902031 10.292969 15.707031 L 7.2929688 12.707031 C 6.9019687 12.316031 6.9019688 11.683969 7.2929688 11.292969 C 7.6839688 10.901969 8.3160313 10.901969 8.7070312 11.292969 L 11 13.585938 L 15.292969 9.2929688 C 15.488469 9.0974688 15.74425 9 16 9 z"></path>
            </svg>
            <h3 className="mt-4 mb-3 font-semibold">Payment Successful</h3>
            <p className="mb-0">Transaction ID:</p>
            <p style={{ color: "#40c057" }}>{paymentRespArr[2]}</p>
            <p className="text-secondary">Thank you for your payment!</p>

            <div className="border rounded px-4 py-4 d-flex align-items-start gap-3 w-100">
              <div className="d-flex flex-col align-items-start gap-2 w-100">
                <span>ID:</span>
                <span>Contact:</span>
                <span>Amount:</span>
                <span>Date:</span>
                <span>Time:</span>
              </div>
              <div className="d-flex flex-col align-items-end gap-2 w-100">
                <span>{paymentRespArr[0]}</span>
                <span>{paymentRespArr[1]}</span>
                <span>₹ {paymentRespArr[3]}.00</span>
                <span>{paymentRespArr[4]}</span>
                <span>{paymentRespArr[5]}</span>
              </div>
            </div>

            <Link
              className="btn btn-primary mt-4 fs-point9 d-flex gap-2"
              to={`../payment-details/${paymentRespArr[0]}/${paymentRespArr[1]}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                height="20"
                width="20"
                version="1.1"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59   c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815   C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029   c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562   C26.18,21.891,26.141,21.891,26.105,21.891z" />
                </g>
              </svg>
              Back
            </Link>
          </main>

          <footer className="flex items-center justify-center border-top">
            <p className="text-secondary mt-3 mx-4 fs-point9 text-center">
              &copy; {new Date().getFullYear()} RedSky Educations Pvt. Ltd. All
              Rights Reserved.
            </p>
          </footer>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default PaymentSuccess;
