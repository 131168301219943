import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "./PaidFeesListStudent.css";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
import logo from "../../assets/img/redsky logo.jpeg";
// import PaidFeesTableRowStudent from '../../components/student_components/PaidFeesTableRowStudent';

const PaymentFailed = (props) => {
  //   props.setTitle("RedSky Digital Passbook | Student Panel | RedSky Educations");
  const param = useParams();

  const [paymentRespArr, setPaymentRespArr] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");

  useEffect(() => {
    setLoaderClasses("loader-container");
    setPaymentRespArr(param.paymentResp.split("&"));
    setLoaderClasses("loader-container d-none");
  }, [param.paymentResp]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Payment Failed | Student Panel | RedSky Educations"
        />
        <title>Payment Failed | Student Panel | RedSky Educations</title>
      </Helmet>

      <div className="d-flex flex-col min-h-screen">
        <header className="d-flex justify-content-center px-4 py-2 border-b">
          <Link to="../">
            <img src={logo} alt="logo" height="120" />
          </Link>
        </header>
        <div className="border rounded shadow-sm mt-4 mx-3">
          <main className="d-flex flex-col align-items-center justify-content-center flex-grow-1 text-center p-4">
            <svg
              fill="#ff0000"
              width="100"
              height="100"
              viewBox="0 -8 528 528"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#ff0000"
            >
              <g id="SVGRepo_bgCarrier"></g>
              <g id="SVGRepo_tracerCarrier"></g>
              <g id="SVGRepo_iconCarrier">
                <title>fail</title>
                <path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z"></path>
              </g>
            </svg>
            <h3 className="mt-3 mb-3 font-semibold">Payment Failed</h3>
            <p className="mb-0">Transaction ID:</p>
            <p className="text-danger">{paymentRespArr[2]}</p>
            <p className="text-secondary">
              Your Payment of <b>₹{paymentRespArr[3]}.00</b> is failed.
              <br />
              on {paymentRespArr[4]} {paymentRespArr[5]}
            </p>

            <div className="border rounded px-4 py-4 d-flex flex-col justify-content-center align-items-start  w-100">
              <p>Please report the issue with us for further processing</p>

              <p className="d-flex w-100 mb-0">
                <span className="material-icons material-symbols-outlined call-icons">
                  call
                </span>
                <Link to="tel:+91 9474897192">9474897192</Link>
              </p>
            </div>

            <Link
              className="btn btn-primary mt-4 fs-point9 d-flex gap-2"
              to={`../payment-details/${paymentRespArr[0]}/${paymentRespArr[1]}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                height="20"
                width="20"
                version="1.1"
                viewBox="0 0 26.676 26.676"
              >
                <g>
                  <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59   c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815   C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029   c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562   C26.18,21.891,26.141,21.891,26.105,21.891z" />
                </g>
              </svg>
              Back
            </Link>
          </main>

          <footer className="flex items-center justify-center border-top">
            <p className="text-secondary mt-3 mx-4 fs-point9 text-center">
              &copy; {new Date().getFullYear()} RedSky Educations Pvt. Ltd. All
              Rights Reserved.
            </p>
          </footer>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default PaymentFailed;
