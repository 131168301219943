import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Policies.css";

const Policies = (props) => {
  const [isLoadVisible, setIsLoadVisible] = useState("");
  const menuBtn = useRef("");
  const navBar = useRef("");

  const menuBtnClickhandler = () => {
    menuBtn.current.classList.toggle("active");
    navBar.current.classList.toggle("d-block");
  };

  useEffect(() => {
    props.setTitle(
      "Policy | Administrator Portal | RedSky Educations | Education for future"
    );
    const onPageLoad = () => {
      setIsLoadVisible("d-none");
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, [props]);

  return (
    <div>
      {/* <!-- ***** Preloader Start ***** --> */}
      <div className={`preloader ${isLoadVisible}`}>
        <div className="preloader-inner">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {/* <!-- ***** Preloader End ***** --> */}

      {/* <!-- ***** Header Area Start ***** --> */}
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                {/* <!-- ***** Logo Start ***** --> */}
                <Link to="./" className="logo">
                  <h4>
                    Red<span>Sky</span>{" "}
                  </h4>
                </Link>
                {/* <!-- ***** Logo End ***** --> */}
                {/* <!-- ***** Menu Start ***** --> */}
                <ul className="nav" ref={navBar}>
                  <li className="scroll-to-section">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="scroll-to-section">
                    <a href="/task_panel/login">Task</a>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/admin_panel/login">Login</Link>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/policies" className="active">
                      Policy
                    </Link>
                  </li>

                  <li className="scroll-to-section">
                    <div className="main-red-button">
                      <Link to="/#contact">Contact Now</Link>
                    </div>
                  </li>
                </ul>
                <button
                  className="menu-trigger"
                  onClick={menuBtnClickhandler}
                  ref={menuBtn}
                >
                  <span>Menu</span>
                </button>
                {/* <!-- ***** Menu End ***** --> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      <div
        className="container py-5"
        style={{ marginTop: "100px" }}
        id="policy"
      >
        <div className="row">
          <div className="col-12">
            {/* Terms & conditions start */}
            <h2 className="mb-3">Terms & Conditions</h2>
            <ol>
              <li>
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and rules there under as
                applicable and the amended provisions pertaining to electronic
                records in various statutes as amended by the Information
                Technology Act, 2000. This electronic record is generated by a
                computer system and does not require any physical or digital
                signatures.
              </li>
              <li>
                This document is published in accordance with the provisions of
                Rule 3 (1) of the Information Technology (Intermediaries
                guidelines) Rules, 2011 that require publishing the rules and
                regulations, privacy policy and Terms of Use for access or usage
                of domain name <b>https://www. redskyeducations.co.in</b>{" "}
                ('Website'), including the related mobile site and mobile
                application (hereinafter referred to as 'Platform').
              </li>
              <li>
                The Platform is owned by{" "}
                <b>REDSKY EDUCATIONS PRIVATE LIMITED</b>, a company incorporated
                under the Companies Act, 1956 with its registered office at{" "}
                <b>Saktinagar, Krishnanagar, Nadia West Bengal 741102, India</b>
                (hereinafter referred to as ‘Platform Owner’, 'we', 'us',
                'our')..
              </li>
              <li>
                Your use of the Platform and services and tools are governed by
                the following terms and conditions (“Terms of Use”) as
                applicable to the Platform including the applicable policies
                which are incorporated herein by way of reference. If You
                transact on the Platform, you shall be subject to the policies
                that are applicable to the Platform for such transaction. By
                mere use of the Platform, you shall be contracting with the
                Platform Owner and these terms and conditions including the
                policies constitute Your binding obligations, with Platform
                Owner. These Terms of Use relate to your use of our website,
                goods (as applicable) or services (as applicable) (collectively,
                'Services'). Any terms and conditions proposed by You which are
                in addition to or which conflict with these Terms of Use are
                expressly rejected by the Platform Owner and shall be of no
                force or effect. These Terms of Use can be modified at any time
                without assigning any reason. It is your responsibility to
                periodically review these Terms of Use to stay informed of
                updates.
              </li>
              <li>
                For the purpose of these Terms of Use, wherever the context
                requires ‘you’, 'your' or ‘user’ shall mean any natural or legal
                person who has agreed to become a user/buyer on the Platform.
              </li>
              <li>
                ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES
                YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS
                OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE THE
                PROCEEDING.
              </li>
              <li>
                The use of Platform and/or availing of our Services is subject
                to the following Terms of Use:
                <br />
                <br />
                <ol className="ms-5">
                  <li>
                    To access and use the Services, you agree to provide true,
                    accurate and complete information to us during and after
                    registration, and you shall be responsible for all acts done
                    using your registered account on the Platform.
                  </li>
                  <li>
                    Neither we nor any third parties provide any warranty or
                    guarantee as to the accuracy, timeliness, performance,
                    completeness or suitability of the information and materials
                    offered on this website or through the Services, for any
                    specific purpose. You acknowledge that such information and
                    materials may contain inaccuracies or errors, and we
                    expressly exclude liability for any such inaccuracies or
                    errors to the fullest extent permitted by law.
                  </li>
                  <li>
                    Your use of our Services and the Platform is solely and
                    entirely at your own risk and discretion for which we shall
                    not be liable to you in any manner. You are required to
                    independently assess and ensure that the Services meet your
                    requirements.
                  </li>
                  <li>
                    The contents of the Platform and the Services are
                    proprietary to us and are licensed to us. You will not have
                    any authority to claim any intellectual property rights,
                    title, or interest in its contents. The contents include and
                    is not limited to the design, layout, look and graphics.
                  </li>
                  <li>
                    You acknowledge that unauthorized use of the Platform and/or
                    the Services may lead to action against you as per these
                    Terms of Use and/or applicable laws.
                  </li>
                  <li>
                    You agree to pay us the charges associated with availing the
                    Services.
                  </li>
                  <li>
                    You agree not to use the Platform and/ or Services for any
                    purpose that is unlawful, illegal or forbidden by these
                    Terms, or Indian or local laws that might apply to you.
                  </li>
                  <li>
                    You agree and acknowledge that website and the Services may
                    contain links to other third- party websites. On accessing
                    these links, you will be governed by the terms of use,
                    privacy policy and such other policies of such third-party
                    websites. These links are provided for your convenience to
                    provide further information.
                  </li>
                  <li>
                    You understand that upon initiating a transaction for
                    availing the Services you are entering into a legally
                    binding and enforceable contract with the Platform Owner for
                    the Services.
                  </li>
                  <li>
                    You shall indemnify and hold harmless Platform Owner, its
                    affiliates, group companies (as applicable) and their
                    respective officers, directors, agents, and employees, from
                    any claim or demand, or actions including reasonable
                    attorney's fees, made by any third party or penalty imposed
                    due to or arising out of Your breach of this Terms of Use,
                    privacy Policy and other Policies, or Your violation of any
                    law, rules or regulations or the rights (including
                    infringement of intellectual property rights) of a third
                    party
                  </li>
                  <li>
                    Notwithstanding anything contained in these Terms of Use,
                    the parties shall not be liable for any failure to fulfil an
                    obligation under these Terms if performance is prevented or
                    delayed by a force majeure event.
                  </li>
                  <li>
                    These Terms and any dispute or claim relating to it, or its
                    enforceability, shall be governed by and construed in
                    accordance with the laws of India.
                  </li>
                  <li>
                    All disputes arising out of or in connection with these
                    Terms shall be subject to the exclusive jurisdiction of the
                    courts in <b>West Bengal</b>.
                  </li>
                  <li>
                    All concerns or communications relating to these Terms must
                    be communicated to us using the contact information provided
                    on this website
                  </li>
                </ol>
              </li>
            </ol>
            {/* Terms & conditions end */}
            {/* Privacy Policy start */}
            <h2 className="mt-5 mb-4">Privacy Policy</h2>
            <p className="mb-3 ms-3">
              <b>Introduction</b>
            </p>
            <p>
              This Privacy Policy describes how{" "}
              <b>REDSKY EDUCATIONS PRIVATE LIMITED</b> and its affiliates
              (collectively "<b>REDSKY EDUCATIONS PRIVATE LIMITED</b>, we, our,
              us") collect, use, share, protect or otherwise process your
              information/ personal data through our website{" "}
              <b>https://www.redskyeducations.co.in</b> (hereinafter referred to
              as <b>Platform</b>). Please note that you may be able to browse
              certain sections of the Platform without registering with us. We
              do not offer any product/service under this Platform outside India
              and your personal data will primarily be stored and processed in
              India. By visiting this Platform, providing your information or
              availing any product/service offered on the Platform, you
              expressly agree to be bound by the terms and conditions of this
              Privacy Policy, the Terms of Use and the applicable
              service/product terms and conditions, and agree to be governed by
              the laws of India including but not limited to the laws applicable
              to data protection and privacy. If you do not agree please do not
              use or access our Platform.
            </p>
            <p>
              <b>Collection</b>-We collect your personal data when you use our
              Platform, services or otherwise interact with us during our
              relationship. And related information provided from time to time.
              Some of the information that we may collect includes but is not
              limited to personal data / information provided to us during
              sign-up/registering or using our Platform such as name, date of
              birth, address, telephone/mobile number, email ID and/or any such
              information shared as proof of identity or address. Some of the
              sensitive personal data may be collected with your consent, such
              as your bank account or credit or debit card or other payment
              instrument information or biometric information such as your
              facial features or physiological information (in order to enable
              use of certain features when opted for, available on the Platform)
              etc. All of the above being in accordance with applicable law(s)
              You always have the option not to provide information, by choosing
              not to use a particular service or feature on the Platform. We may
              track your behavior, preferences, and other information that you
              choose to provide on our Platform.
              <br />
              This information is compiled and analyzed on an aggregated basis.
              We will also collect your information related to your transactions
              on Platform and such third-party business partner platforms. When
              such a third-party business partner collects your personal data
              directly from you, you will be governed by their privacy policies.
              We shall not be responsible for the third-party business partner’s
              privacy practices or the content of their privacy policies, and we
              request you to read their privacy policies prior to disclosing any
              information. If you receive an email, a call from a
              person/association claiming to be
              <b> REDSKY EDUCATIONS PRIVATE LIMITED</b> seeking any personal
              data like debit/credit card PIN, netbanking or mobile banking
              password, we request you to never provide such information. If you
              have already revealed such information, report it immediately to
              an appropriate law enforcement agency.
            </p>
            <p>
              <b>Usage</b>- We use personal data to provide the services you
              request. To the extent we use your personal data to market to you,
              we will provide you with the ability to opt out of such uses. We
              use your personal data to assist sellers and business partners in
              handling and fulfilling orders; enhancing customer experience; to
              resolve disputes; troubleshoot problems; inform you about online
              and offline offers, products, services, and updates; customize
              your experience; detect and protect us against error, fraud and
              other criminal activity; enforce our terms and conditions; conduct
              marketing research, analysis and surveys; and as otherwise
              described to you at the time of collection of information. You
              understand that your access to these products/services may be
              affected in the event permission is not provided to us.
            </p>
            <p>
              <b>Sharing</b>- We may share your personal data internally within
              our group entities, our other corporate entities, and affiliates
              to provide you access to the services and products offered by
              them. These entities and affiliates may market to you because of
              such sharing unless you explicitly opt-out. We may disclose
              personal data to third parties such as sellers, business partners,
              third party service providers including logistics partners,
              prepaid payment instrument issuers, third-party reward programs
              and other payment opted by you. This disclosure may be required
              for us to provide you access to our services and products offered
              to you, to comply with our legal obligations, to enforce our user
              agreement, to facilitate our marketing and advertising activities,
              to prevent, detect, mitigate, and investigate fraudulent or
              illegal activities related to our services. We may disclose
              personal and sensitive personal data to government agencies or
              other authorized law enforcement agencies if required to do so by
              law or in the good faith belief that such disclosure is reasonably
              necessary to respond to subpoenas, court orders, or other legal
              process. We may disclose personal data to law enforcement offices,
              third party rights owners, or others in the good faith belief that
              such disclosure is reasonably necessary to: enforce our Terms of
              Use or Privacy Policy; respond to claims that an advertisement,
              posting or other content violates the rights of a third party; or
              protect the rights, property or personal safety of our users or
              the general public.
            </p>
            <p>
              <b>Security Precautions</b>- To protect your personal data from
              unauthorized access or disclosure, loss or misuse we adopt
              reasonable security practices and procedures. Once your
              information is in our possession or whenever you access your
              account information, we adhere to our security guidelines to
              protect it against unauthorized access and offer the use of a
              secure server. However, the transmission of information is not
              completely secure for reasons beyond our control. By using the
              Platform, the users accept the security implications of data
              transmission over the internet and the World Wide Web which cannot
              always be guaranteed as completely secure, and therefore, there
              would always remain certain inherent risks regarding use of the
              Platform. Users are responsible for ensuring the protection of
              login and password records for their account.
            </p>
            <p>
              <b>Data Deletion and Retention</b>- You have an option to delete
              your account by visiting your profile and settings on our
              Platform, this action would result in you losing all information
              related to your account. You may also write to us at the contact
              information provided below to assist you with these requests. We
              may in the event of any pending grievance, claims, pending
              shipments or any other services we may refuse or delay deletion of
              the account. Once the account is deleted, you will lose access to
              the account. We retain your personal data information for a period
              no longer than is required for the purpose for which it was
              collected or as required under any applicable law. However, we may
              retain data related to you if we believe it may be necessary to
              prevent fraud or future abuse or for other legitimate purposes. We
              may continue to retain your data in anonymized form for analytical
              and research purposes.
            </p>
            <p>
              <b>Your Rights</b>- You may access, rectify, and update your
              personal data directly through the functionalities provided on the
              Platform.
            </p>
            <p>
              <b>Consent</b>- By visiting our Platform or by providing your
              information, you consent to the collection, use, storage,
              disclosure and otherwise processing of your information on the
              Platform in accordance with this Privacy Policy. If you disclose
              to us any personal data relating to other people, you represent
              that you have the authority to do so and permit us to use the
              information in accordance with this Privacy Policy. You, while
              providing your personal data over the Platform or any partner
              platforms or establishments, consent to us (including our other
              corporate entities, affiliates, lending partners, technology
              partners, marketing channels, business partners and other third
              parties) to contact you through SMS, instant messaging apps, call
              and/or e-mail for the purposes specified in this Privacy Policy.
              You have an option to withdraw your consent that you have already
              provided by writing to the Grievance Officer at the contact
              information provided below. Please mention “Withdrawal of consent
              for processing personal data” in your subject line of your
              communication. We may verify such requests before acting on our
              request. However, please note that your withdrawal of consent will
              not be retrospective and will be in accordance with the Terms of
              Use, this Privacy Policy, and applicable laws. In the event you
              withdraw consent given to us under this Privacy Policy, we reserve
              the right to restrict or deny the provision of our services for
              which we consider such information to be necessary.
            </p>
            <p>
              <b>Changes to this Privacy Policy</b>- Please check our Privacy
              Policy periodically for changes. We may update this Privacy Policy
              to reflect changes to our information practices. We may alert /
              notify you about the significant changes to the Privacy Policy, in
              the manner as may be required under applicable laws.
            </p>
            <p>
              <b>Grievance Officer</b>
              <br />
              Anirban Das
              <br />
              Managing Director
              <br />
              RedSky Educations Private Limited
              <br />
              Contact us: 9474897192
              <br />
              Phone: Time: Wednesday - Monday (9:00AM – 8.00PM)
            </p>
            {/* Privacy Policy end */}
            {/* Refund and Cancellation Policy start */}
            <h2 className="mt-5 mb-4">Refund and Cancellation policy</h2>
            <p>
              This refund and cancellation policy outlines how you can cancel or
              seek a refund for a product / service that you have paid through
              the Platform. Under this policy:
            </p>
            <ol>
              <li>
                Cancellations will only be considered if the request is made
                <b> 7 days</b> of placing the payment. However, cancellation
                requests may not be entertained if the orders have been
                communicated to such sellers / merchant(s) listed on the
                Platform and they have initiated the process of shipping them,
                or the product is out for delivery. In such an event, you may
                choose to reject the product at the doorstep.
              </li>
              <li>
                <b>REDSKY EDUCATIONS PRIVATE LIMITED</b> does not accept
                cancellation requests for perishable items like flowers,
                eatables, etc. However, the refund / replacement can be made if
                the user establishes that the quality of the product delivered
                is not good.
              </li>
              <li>
                In case of receipt of damaged or defective items, please report
                to our customer service team. The request would be entertained
                once the seller/ merchant listed on the Platform, has checked
                and determined the same at its own end. This should be reported
                within <b> 7 days</b> of receipt of products. In case you feel
                that the product received is not as shown on the site or as per
                your expectations, you must bring it to the notice of our
                customer service within <b> 7 days</b> of receiving the product.
                The customer service team after looking into your complaint will
                take an appropriate decision.
              </li>
              <li>
                In case of complaints regarding the products that come with a
                warranty from the manufacturers, please refer the issue to them.
              </li>
              <li>
                In case of any refunds approved by{" "}
                <b>REDSKY EDUCATIONS PRIVATE LIMITED</b>, it will take{" "}
                <b> 7 days </b>
                for the refund to be processed to you.
              </li>
            </ol>
            {/* Refund and Cancellation policy end */}
            {/* Return Policy start */}
            {/* <h2 className="mt-5 mb-4">Return Policy</h2>
            <p>
              We offer refund / exchange within first <b> 7 days</b> from the
              date of your purchase. If <b> 7 days</b> have passed since your
              purchase, you will not be offered a return, exchange or refund of
              any kind. In order to become eligible for a return or an exchange,
              (i) the purchased item should be unused and in the same condition
              as you received it, (ii) the item must have original packaging,
              (iii) if the item that you purchased on a sale, then the item may
              not be eligible for a return / exchange. Further, only such items
              are replaced by us (based on an exchange request), if such items
              are found defective or damaged.
            </p>
            <p>
              You agree that there may be a certain category of products / items
              that are exempted from returns or refunds. Such categories of the
              products would be identified to you at the item of purchase. For
              exchange / return accepted request(s) (as applicable), once your
              returned product / item is received and inspected by us, we will
              send you an email to notify you about receipt of the returned /
              exchanged product. Further. If the same has been approved after
              the quality check at our end, your request (i.e. return /
              exchange) will be processed in accordance with our policies.
            </p> */}
            {/* Return Policy end */}
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.25s"
            >
              <p>
                © Copyright @ {new Date().getFullYear()} RedSky Educations Pvt.
                Ltd. All Rights Reserved.
                <br />
                Design & Developed By{" "}
                <Link
                  rel="nofollow noreferrer"
                  target="_blank"
                  to="https://www.linkedin.com/in/iamgourabtalukdar/"
                >
                  Gourab Talukdar
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* <h2>Home</h2>
      <Link to='./faq'>FAQ</Link>
      <br />
      <Link to='./student_panel'>Student</Link>
      <br />
      <Link to='./admin_panel'>Admin</Link> */}
    </div>
  );
};

export default Policies;
