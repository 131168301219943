import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "./PaidFeesListStudent.css";
import { useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
// import PaidFeesTableRowStudent from '../../components/student_components/PaidFeesTableRowStudent';

const PaymentUnpaid = (props) => {
  //   props.setTitle("RedSky Digital Passbook | Student Panel | RedSky Educations");
  const param = useParams();
  const paymentForm = useRef();
  const installmentNoElem = useRef();
  const forMonthElem = useRef();
  const forYearElem = useRef();

  const [singleStudentData, setSingleStudentData] = useState({});
  const [unpaidFeesLists, setUnpaidFeesLists] = useState([]);
  const [deferralFeesLists, setDeferralFeesLists] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");

  useEffect(() => {
    setLoaderClasses("loader-container");
    setSingleStudentData({});
    setUnpaidFeesLists([]);
    setDeferralFeesLists([]);

    fetch(`${props.hostName}/redsky_api/student/get_unpaid_fees_data.php`, {
      method: "POST",
      body: JSON.stringify({
        stu_reg_no: param.reg_no,
        contact: param.contact,
      }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          console.log(data);
          setSingleStudentData(data.data["stu_data"]);
          setUnpaidFeesLists(data.data["unpaid_fees_data"]);
          setDeferralFeesLists(data.data["deferral_fees_data"]);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  }, [props.hostName, props.token, param]);

  const initiatePayment = (installment_no, for_month, for_year) => {
    installmentNoElem.current.value = installment_no;
    forMonthElem.current.value = for_month;
    forYearElem.current.value = for_year;
    paymentForm.current.submit();
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Pay Unpaid Fees Online | Student Panel | RedSky Educations"
        />
        <title>
          Pay Unpaid Fees Online | Student Panel | RedSky Educations
        </title>
      </Helmet>

      <form
        action={`${props.hostName}/redsky_api/payment/pay.php`}
        method="POST"
        className="d-none"
        ref={paymentForm}
      >
        <input
          type="text"
          name="sub_id"
          defaultValue={singleStudentData.sub_id}
        />
        <input type="number" name="installment_no" ref={installmentNoElem} />
        <input type="number" name="for_month" ref={forMonthElem} />
        <input type="number" name="for_year" ref={forYearElem} />
      </form>

      <section className="form-container mt-4">
        <header>Student Details</header>
        <form className="student-short-details-form">
          <div className="column">
            <div className="input-box field-disabled">
              <label>Student Id</label>
              <input
                type="text"
                value={`${singleStudentData.reg_no}`}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="input-box field-disabled">
              <label>Name</label>
              <input
                type="text"
                value={`${singleStudentData.first_name} ${singleStudentData.last_name}`}
                onChange={() => {}}
                disabled
              />
            </div>
          </div>

          <div className="column">
            <div className="input-box field-disabled">
              <label>Phone Number</label>
              <input
                type="text"
                value={singleStudentData.contact}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="input-box field-disabled">
              <label>Course</label>
              <input
                type="text"
                value={singleStudentData.course_name}
                onChange={() => {}}
                disabled
              />
            </div>
          </div>
        </form>
      </section>

      <section className="form-container ">
        <header>Unpaid Fees Details</header>

        <table className="unpaid-table">
          <thead>
            <tr>
              <th scope="col" className="fit">
                No
              </th>
              <th scope="col">Due Month</th>
              <th scope="col">Amount</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {unpaidFeesLists.length > 0 || deferralFeesLists.length > 0 ? (
              unpaidFeesLists.map((unpaidFeesRowData) => {
                return (
                  <tr key={unpaidFeesRowData.installment_no}>
                    <td data-label="No">{unpaidFeesRowData.installment_no}</td>
                    <td data-label="Due Month">
                      {unpaidFeesRowData.for_month_year_text}
                    </td>
                    <td data-label="Amount">
                      ₹ {unpaidFeesRowData.course_fees}
                    </td>

                    <td data-label="Action">
                      <button
                        type="submit"
                        className="btn btn-primary fs-point9"
                        onClick={() =>
                          initiatePayment(
                            unpaidFeesRowData.installment_no,
                            unpaidFeesRowData.for_month,
                            unpaidFeesRowData.for_year
                          )
                        }
                      >
                        Pay Now
                      </button>
                      {/* </form> */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">
                  <center>No Unpaid Payment Details Found</center>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className={`payment-btn-container ${isShowPaymentBtn}`}>
					<button  className='payment-btn online-btn' onClick={()=>submitNewPaymentHandler('online')}>Online</button>
					<button className='payment-btn cash-btn' onClick={()=>submitNewPaymentHandler('cash')}>Cash</button>
				</div> */}
      </section>

      {/* <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]}  proceedPinHandler={proceedPinHandler} cancelPinHandler={cancelPinHandler} /> */}

      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default PaymentUnpaid;
