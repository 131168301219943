import React from "react";
import Home from "./pages/Home";
import LoginAdmin from "./pages/admin_panel/LoginAdmin";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import AdminIndex from "./pages/admin_panel/AdminIndex";
import TaskLogin from "./pages/task_manager/TaskLogin";
import TaskIndex from "./pages/task_manager/TaskIndex";
import StudentIndex from "./pages/student_panel/StudentIndex";
import "./App.css";
import Profile from "./pages/student_panel/Profile";
import ProfileCertificate from "./pages/student_panel/ProfileCertificate";
import { Helmet } from "react-helmet";
import Policies from "./pages/Policies";

function App() {
  // const hostName = "http://localhost/redsky_api";
  // const hostName = 'https://redskyeducations.co.in/';
  const hostName =
    "https://redskyeducations.co.in/static/css/tailwind_css/csstools/postcss-nested-calc/dist/arg/beta_css";

  const setTitle = (title = "RedSky Educations") => (document.title = title);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="RedSky Education is a reputable computer course institute that offers a wide range of educational programs and training in the field of computer science and technology. With a commitment to providing high-quality education, RedSky Education aims to equip individuals with the skills and knowledge necessary to thrive in the rapidly evolving world of technology.

    The institute prides itself on its experienced and knowledgeable faculty who are experts in their respective fields. They are dedicated to delivering comprehensive and up-to-date curriculum that covers various aspects of computer science, including programming languages, software development, database management, networking, web development, and more.
    
    RedSky Education offers a diverse selection of courses to cater to the needs and interests of students at different levels. Whether you are a beginner looking to acquire fundamental computer skills or an experienced professional seeking to enhance your expertise, the institute provides courses that are tailored to your specific requirements. The courses are designed to be interactive and hands-on, allowing students to gain practical experience through projects, assignments, and real-world case studies.
    
    The institute maintains a modern and well-equipped learning environment, with state-of-the-art computer labs and the latest software and hardware resources. This ensures that students have access to the tools and technologies commonly used in the industry, enabling them to develop practical skills that are in high demand.
    
    In addition to its core curriculum, RedSky Education also offers specialized training programs and workshops on emerging technologies and industry trends. These programs provide students with the opportunity to explore cutting-edge concepts such as artificial intelligence, data science, cybersecurity, cloud computing, and more.
    
    RedSky Education takes pride in its track record of success, with many of its graduates securing rewarding careers in leading technology companies or starting their own ventures. The institute also provides career guidance and placement assistance to help students transition smoothly into the workforce.
    
    Overall, RedSky Education is a reputable computer course institute that offers comprehensive and industry-focused training programs. By combining theoretical knowledge with practical experience, the institute aims to empower individuals with the skills they need to succeed in the rapidly advancing world of technology."
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home setTitle={setTitle} />} />
        <Route path="/policies" element={<Policies setTitle={setTitle} />} />
        <Route
          path="/profile/:sub_id"
          element={<Profile hostName={hostName} setTitle={setTitle} />}
        />
        <Route
          path="/certificate/:sub_id/:marks_id"
          element={
            <ProfileCertificate hostName={hostName} setTitle={setTitle} />
          }
        />
        <Route
          path="/student/*"
          element={<StudentIndex hostName={hostName} setTitle={setTitle} />}
        />
        <Route
          path="/task_panel/login"
          element={<TaskLogin hostName={hostName} setTitle={setTitle} />}
        />
        <Route
          path="/task_panel/verify/*"
          element={<TaskIndex hostName={hostName} setTitle={setTitle} />}
        />
        <Route
          path="/admin_panel/login"
          element={<LoginAdmin hostName={hostName} setTitle={setTitle} />}
        />
        <Route
          path="/admin_panel/verify/*"
          element={<AdminIndex hostName={hostName} setTitle={setTitle} />}
        />
        <Route path="/*" element={<PageNotFound setTitle={setTitle} />} />
      </Routes>
    </div>
  );
}

export default App;
